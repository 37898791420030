<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="text-right mb-2">
      <b-button-group v-if="invoice">
        <b-dropdown
          right
          variant="gradient-primary"
          text="XML"
          v-if="
            (user_state && user_state.role == 'admin') ||
              permissions.includes('download_xmls_invoice')
          "
        >
          <b-dropdown-item
            @click="downloadXml(invoice.key50digits, 'fe', 'send')"
          >
            Xml enviado
          </b-dropdown-item>
          <b-dropdown-item
            v-if="['aceptado', 'rechazado'].includes(invoice.hacienda)"
            @click="downloadXml(invoice.key50digits, 'fe', 'response')"
          >
            Xml respuesta
          </b-dropdown-item>
        </b-dropdown>
        <b-overlay
          :show="action_execute == 'pdf'"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            variant="gradient-primary"
            @click="generatePdf()"
            v-if="
              (user_state && user_state.role == 'admin') ||
                permissions.includes('generate_pdf_invoice')
            "
          >
            <feather-icon icon="FileTextIcon" class="mr-50" />
            <span class="align-middle">Pdf</span>
          </b-button>
        </b-overlay>
        <b-button
          variant="gradient-primary"
          @click="sendEmail(invoice)"
          v-if="
            (user_state && user_state.role == 'admin') ||
              permissions.includes('send_email_invoice')
          "
        >
          <feather-icon icon="MailIcon" class="mr-50" />
          <span class="align-middle">Enviar por correo</span>
        </b-button>
      </b-button-group>
    </div>

    <div class="d-flex" v-if="invoice">
      <div class="sidebar-left">
        <div class="sidebar">
          <div class="sidebar-content card">
            <!-- Header -->
            <div class="chat-fixed-search">
              <div class="d-flex align-items-center w-100">
                <b-input-group class="input-group-merge w-100 round">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" class="text-muted" />
                  </b-input-group-prepend>
                  <b-form-input placeholder="Buscar" />
                </b-input-group>
              </div>
            </div>

            <!-- ScrollArea: Chat & Contacts -->
            <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="chat-user-list-wrapper list-group scroll-area"
            >
              <!-- Contacts Title -->
              <h4 class="chat-list-title">
                Otras facturas
              </h4>

              <!-- Contacts -->
              <ul class="chat-users-list contact-list media-list">
                <item-list-invoice
                  v-for="invoice of others_invoice"
                  :key="invoice.id"
                  :invoice="invoice"
                  @click="openInvoice(invoice.id)"
                />
              </ul>
            </vue-perfect-scrollbar>
          </div>
        </div>
      </div>

      <section
        class="invoice-preview-wrapper"
        style="width: 100%; position: relative"
      >
        <b-row class="invoice-preview">
          <b-col>
            <b-card
              no-body
              class="invoice-preview-card"
              style="border-radius: 0px !important"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <status-hacienda
                  :ribbon="true"
                  :status_hacienda="invoice.hacienda"
                />

                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <b-img
                        style="width: 150px"
                        fluid
                        src="@/assets/images/pages/login/logo_tavo.png"
                        alt="Login V2"
                      />
                    </div>
                    <p class="card-text mb-25">
                      {{ $store.state.app.company_info.name }}
                    </p>
                    <p class="card-text mb-25">
                      {{ $store.state.app.company_info.address }}
                    </p>
                    <p class="card-text mb-0">
                      {{ $store.state.app.company_info.phone }}
                    </p>
                  </div>

                  <!-- Header: Right Content -->
                  <div class="mt-md-0 mt-2">
                    <h4 class="invoice-title">
                      Factura
                      <span class="invoice-number"
                        >#{{ invoice.reference }}</span
                      >
                    </h4>
                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">
                        Fecha de la factura:
                      </p>
                      <p class="invoice-date">
                        {{
                          formatDate(invoice.created_at, "DD MMMM YYYY, h:m a")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing" />

              <!-- Invoice Client & Payment Details -->
              <b-card-body v-if="invoice.client" class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <!-- Col: Invoice To -->
                  <b-col cols="12" xl="6" class="p-0">
                    <h6 class="mb-2">
                      <b>Facturar a:</b>
                    </h6>
                    <h6 class="mb-25">
                      {{ invoice.client.full_name }}
                    </h6>
                    <h6 class="mb-25">
                      {{
                        identificationInLetter(
                          invoice.client.identification_type
                        )
                      }}: {{ invoice.client.identification_number }}
                    </h6>
                    <p class="card-text mb-25">{{ invoice.client.address }},</p>
                    <p class="card-text mb-25">
                      {{ invoice.client.phone }}
                    </p>
                  </b-col>

                  <!-- Col: Payment Details -->
                  <b-col
                    xl="6"
                    cols="12"
                    class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                  >
                    <div>
                      <h6 class="mb-2">
                        Detalle pago:
                      </h6>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1">
                              <b>Método de pago:</b>
                            </td>
                            <td>
                              <span class="font-weight-bold">
                                {{ invoice.payment_method.name }}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              <b>Medio de pago:</b>
                            </td>
                            <td>
                              <span class="font-weight-bold">
                                {{ invoice.payment_form.name }}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              <b>Total:</b>
                            </td>
                            <td>
                              <span class="font-weight-bold">
                                {{ currencyFormat(invoice.total) }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="invoice-spacing">
                  <b-col
                    xl="12"
                    cols="12"
                    class="p-0 mt-xl-0 mt-2 d-flex justify-content-start"
                  >
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1">
                              <b>Consecutivo:</b>
                            </td>
                            <td>
                              <span class="font-weight-bold">
                                {{ invoice.reference }}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              <b>Clave:</b>
                            </td>
                            <td>
                              <span class="font-weight-bold">
                                {{ invoice.key50digits }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Invoice Description: Table -->
              <b-table-lite
                responsive
                :items="invoice.transaction_details"
                :fields="[
                  { key: 'cabys_code', label: 'Código Cabys' },
                  { key: 'name', label: 'Servicio' },
                  { key: 'price', label: 'Precio' },
                  { key: 'tax', label: 'Iva' }
                ]"
              >
                <template #cell(price)="data">
                  <b-card-text class="font-weight-bold mb-25">
                    {{ currencyFormat(data.item.price) }}
                  </b-card-text>
                </template>

                <template #cell(tax)="data">
                  <b-card-text class="font-weight-bold mb-25">
                    {{ parseInt(data.item.tax) }}%
                  </b-card-text>
                </template>
              </b-table-lite>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <!-- Col: Sales Persion -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text class="mb-0">
                      <span class="font-weight-bold">
                        Notas:
                      </span>
                      <span class="ml-75">{{ invoice.comment }}</span>
                      <hr>
                      <span class="font-weight-bold"
                        >Terminos y condiciones:</span
                      >
                      <span class="ml-75">{{ invoice.term }}</span>
                    </b-card-text>
                  </b-col>

                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Subtotal:
                        </p>
                        <p class="invoice-total-amount">
                          {{ currencyFormat(invoice.subtotal) }}
                        </p>
                      </div>
                      <hr class="my-50" />
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          IVA:
                        </p>
                        <p class="invoice-total-amount">
                          {{ currencyFormat(invoice.tax) }}
                        </p>
                      </div>
                      <hr class="my-50" />
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total:
                        </p>
                        <p class="invoice-total-amount">
                          {{ currencyFormat(invoice.total) }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing" />

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <div v-if="invoice.note">
                  <span class="font-weight-bold">Note: </span>
                  <span>{{ invoice.note }}</span>
                </div>

                <div class="text-center">
                  <span class="font-weight-bold">
                    Autorizada mediante resolucion DGT-R-033-2019 del 20 de
                    junio de 2019 version 4.3
                  </span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </section>

      <!-- component send email document -->
      <b-sidebar
        v-model="showSendEmailModal"
        id="sidebar-right"
        title="Enviar correo"
        right
        shadow
        width="30%"
      >
        <div class="col-md-12">
          <form-document-email
            @sendEmail="emailSent"
            :document="email_document"
            :email="dataSendEmail"
            document_type="fe"
          />
        </div>
      </b-sidebar>
      <!-- component send email document -->
    </div>
  </b-overlay>
</template>

<script>
import InvoiceService from "@/services/invoices.service";
import moment from "moment";
moment.locale("es");
import { mapState } from "vuex";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInput,
  BCard,
  BRow,
  BCol,
  BCardText,
  BCardBody,
  BTableLite,
  BImg,
  BButton,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BSidebar
} from "bootstrap-vue";
import {
  currencyFormat,
  downloadBlob,
  identificationInLetter
} from "@/helpers";
import ItemListInvoice from "@/components/ItemListInvoice";
import FormDocumentEmail from "@/components/FormDocumentEmail.vue";
import utilitiesService from "@/services/utilities.service";

export default {
  components: {
    BSidebar,
    FormDocumentEmail,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    BButton,
    BImg,
    BOverlay,
    VuePerfectScrollbar,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    ItemListInvoice,
    BCard,
    BRow,
    BCol,
    BCardText,
    BCardBody,
    BTableLite
  },
  computed: {
    ...mapState({
      user_state: state => state.app.user,
      permissions: state => state.app.permissions
    })
  },
  data() {
    return {
      email_document: null,
      showSendEmailModal: false,
      dataSendEmail: {
        subject: "",
        content: "",
        to: ""
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 150
      },
      invoice: null,
      loading: false,
      others_invoice: [],
      action_execute: ""
    };
  },
  methods: {
    downloadXml(key50digits, document_type, folder) {
      utilitiesService
        .downloadXml(key50digits, document_type, folder)
        .then(response => {
          downloadBlob(response.data, `${key50digits}.xml`);
        })
        .catch(error => {
          alert("error");
        });
    },
    downloadFile(file, namefile) {
      return downloadBlob(file, namefile);
    },
    emailSent() {
      this.dataSendEmail = {
        subject: "",
        content: "",
        to: ""
      };
      this.email_document = null;
      this.showSendEmailModal = false;
    },
    sendEmail(doc) {
      this.email_document = doc;
      this.dataSendEmail.to = doc.client.email;
      this.showSendEmailModal = true;
    },
    identificationInLetter(number) {
      return identificationInLetter(number);
    },
    async getInvoice() {
      try {
        this.loading = true;
        const { data: res } = await InvoiceService.getInvoice(
          this.$route.params.id
        );
        if (res.success) {
          this.invoice = res.data.invoice;
          this.others_invoice = res.data.others_invoice;
        } else {
          this.swal("Ha ocurrido un error al cargar la factura");
        }
        this.loading = false;
      } catch (error) {
        this.swal("Ha ocurrido un error al cargar la factura");
      }
    },
    async generatePdf() {
      try {
        this.action_execute = "pdf";

        const { data: res } = await InvoiceService.generatePdf(this.invoice.id);

        this.downloadFile(res, `Factura-${this.invoice.reference}.pdf`);
        this.action_execute = "";
      } catch (e) {
        this.action_execute = "";
      }
    },
    openInvoice(id) {
      this.$router.replace(`/invoice-detail/${id}`);
      this.getInvoice();
    },
    formatDate(date, format = "DD/MM/YYYY") {
      return moment(date).format(format);
    },
    currencyFormat(amount) {
      return currencyFormat(amount);
    }
  },
  created() {
    this.getInvoice();
  }
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
